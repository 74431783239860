.region {
    transition: all 500ms ease;
    cursor: pointer;
}

/* .region:hover {
    fill: ;
} */

.region:active {
    fill: red;
}

.region:hover::after {
    content: "asd";
}
