.react-chatbot-kit-chat-container {
    width: 400px;
}

.react-chatbot-kit-chat-inner-container {
    height: 560px;
    overflow-x: hidden;
}

.react-chatbot-kit-chat-input-form {
    height: 60px;
}

.react-chatbot-kit-chat-bot-message {
    width: 75%;
}

@media only screen and (max-width: 568px) {
    .react-chatbot-kit-chat-inner-container {
        height: 428px;
    }
    .react-chatbot-kit-chat-container {
        width: 318px;
    }
    .react-chatbot-kit-chat-input-form {
        height: 50px;
    }
}
